<template>
  <div v-if="form">
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ระบบปรับเปลี่ยนระดับผู้ใช้
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3">
        <!-- <b-row v-if="isLoadingData">
          <b-col class="px-4 px-sm-5 py-4 vh-100">
            <img src="/img/loading.svg" class="loading" alt="loading" />
          </b-col>
        </b-row> -->

        <b-row class="no-gutters">
          <b-col class="mb-3">
            <h1
              class="font-weight-bold header-main text-uppercase f-20 d-inline-block mr-2"
            >
              สำหรับจัดการบัญชี
            </h1>
          </b-col>
        </b-row>

        <div>
          <h1
            class="font-weight-bold header-main text-uppercase f-16 d-inline-block mr-2 mb-3"
          >
            กรุณาระบุอีเมลเพื่อทำการปรับหรือกำหนดระดับให้กับบัญชี
          </h1>
        </div>

        <b-row>
          <b-col md="6">
            <InputText
              textFloat="ระบุอีเมลที่ต้องการปรับ"
              placeholder="ระบุอีเมลที่ต้องการปรับ"
              type="text"
              name="silver"
              isRequired
              v-model="form.email"
              :isValidate="$v.form.email.$error"
              :v="$v.form.email"
            />
          </b-col>
          <b-col md="6">
            <label class="main-label"
              >ระดับที่ต้องการปรับ <span class="text-danger">*</span></label
            >
            <b-form-group class="my-1" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.tierId"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
                class="font-weight-normal"
              >
                <b-form-radio value="3">Silver</b-form-radio>
                <b-form-radio value="2">Gold</b-form-radio>
                <b-form-radio value="1">Platinum</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <InputText
              textFloat="ระบุยอดซื้อย้อนหลัง (บาท)"
              placeholder="ระบุยอดซื้อย้อนหลัง (บาท)"
              type="text"
              name="silver"
              @onKeypress="isNumber($event)"
              isRequired
              v-model="form.total"
              :isValidate="$v.form.total.$error"
              :v="$v.form.total"
            />
          </b-col>
        </b-row>

        <hr />

        <b-row class="mt-3">
          <b-col md="6"></b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>

      <div class="mt-3">
        <b-row>
          <b-col>
            <h3 class="font-weight-bold header-main mb-2 mt-3">
              ประวัติการเปลี่ยน Tier
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-between pb-3 pt-2">
              <div class="d-flex">
                <b-form-select
                  v-model="filter.Year"
                  :options="optionsFilterYear"
                  class="w-200"
                  @change="getList"
                ></b-form-select>
                <b-form-select
                  v-model="filter.Month"
                  :options="optionsFilterMonth"
                  class="w-200 ml-2"
                  @change="getList"
                ></b-form-select>
              </div>
              <div class="d-flex flex-fill">
                <b-input-group class="panel-input-serach ml-auto">
                  <b-form-input
                    class="input-serach"
                    placeholder="ค้นหาด้วย อีเมล, ชื่อ, เบอร์โทรศัพท์"
                    v-model="filter.Search"
                    @keyup="handleSearch"
                  ></b-form-input>
                  <b-input-group-prepend @click="btnSearch">
                    <span class="icon-input m-auto pr-2">
                      <font-awesome-icon
                        icon="search"
                        title="View"
                        class="icon-search"
                      />
                    </span>
                  </b-input-group-prepend>
                </b-input-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="bg-white no-gutters px-3 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(createdTime)="data">
                <span>{{
                  new Date(data.item.createdTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(updateQuantity)="data">
                <p class="m-0">
                  {{ data.item.updateQuantity | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:cell(name)="data">
                <div>
                  <div>{{ data.item.name }}</div>
                  <div>{{ data.item.telephone }}</div>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0 bg-white">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  ทั้งหมด {{ rows }} รายการ
                </p>
              </div>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>

      <ModalAlert ref="modalAlert" :text="modalMessage" />
      <ModalAlertError ref="modalAlertError" :text="modalMessage" />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </b-container>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required, email, integer } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      isDisable: false,
      modalMessage: "",
      form: {
        email: "",
        tierId: 1,
        total: 0,
      },
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        StartDate: null,
        EndDate: null,
        status: [],
        Status: [],
        Search: "",
        tier: 0,
        Tier: 0,
        MinPoint: 0,
        MaxPoint: 0,
        MinSale: 0,
        MaxSale: 0,
        MinCashback: 0,
        MaxCashback: 0,
        SortByOrderOrId: 0,
        Month: null,
        Year: null,
      },
      fields: [
        {
          key: "createdTime",
          label: "วันที่ทำรายการ",
          class: "w-50px",
        },
        {
          key: "name",
          label: "รายละเอียด Seller",
          class: "w-50px",
        },
        {
          key: "email",
          label: "Email",
          class: "w-50px",
        },
        {
          key: "oldTierName",
          label: "Tier เดิม",
          class: "w-50px",
        },
        {
          key: "tierName",
          label: "Tier ที่เปลี่ยน",
          class: "w-50px",
        },
        {
          key: "updateQuantity",
          label: "ยอดซื้อย้อนหลัง",
          class: "w-50px",
        },
      ],
      items: [],
      isBusy: false,
      options: [{ value: null, text: "สถานะ" }],
      optionsFilterMonth: [
        { value: null, text: "กรุณาเลือกเดือน" },
        { value: 1, text: "มกราคม" },
        { value: 2, text: "กุมภาพันธ์" },
        { value: 3, text: "มีนาคม" },
        { value: 4, text: "เมษายน" },
        { value: 5, text: "พฤษภาคม" },
        { value: 6, text: "มิถุนายน" },
        { value: 7, text: "กรกฎาคม" },
        { value: 8, text: "สิงหาคม" },
        { value: 9, text: "กันยายน" },
        { value: 10, text: "ตุลาคม" },
        { value: 11, text: "พฤศจิกายน" },
        { value: 12, text: "ธันวาคม" },
      ],
      optionsFilterYear: [{ value: null, text: "กรุณาเลือกปี" }],
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
    };
  },
  validations: {
    form: {
      email: { required, email },
      total: { required, integer },
    },
  },
  created: async function() {
    this.$isLoading = true;
    let year = parseInt(this.$moment(new Date()).format("YYYY"));
    for (var i = 0; i < 4; i++) {
      this.optionsFilterYear.push({ value: year - i, text: `${year - i}` });
    }
    this.getList();
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        if (charCode == 46) return true;
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkForm: async function(flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function() {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Affiliate/Profile/SettingTier`,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.$v.form.$reset();
        this.form = {
          email: "",
          tierId: 1,
          total: 0,
        };
        this.pagination(1);
      } else {
        this.modalMessage = data.message;
        this.$refs.modalAlertError.show();
      }
    },
    getList: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Affiliate/Profile/SettingTier/Log`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
      }
      this.isBusy = false;
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.icon-search {
  margin-top: 8px;
}
</style>
